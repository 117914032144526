import React, { useEffect, useRef, useState } from "react";
import { Link } from "../links";
import gsap from "gsap";
import classNames from "classnames";
import "./cta.scss";

const CTA = ({
  children,
  to,
  isOutbound = false,
  glow,
  black,
  button = false,
  submitted = false,
  className = "",
  isAnchor = false,
  disable = false,
}) => {
  const waterFillRef = useRef(null);
  const ctaRef = useRef(null);
  const [tl, setTimeline] = useState(null);

  const ctaClasses = classNames("cs-cta cursor-pointer", {
    "cs-cta--submitted cursor-pointer": submitted || disable,
  });

  useEffect(() => {
    const waterFillElement = waterFillRef.current;
    const ctaElement = ctaRef.current;

    if (!submitted || !disable) {
      const waterFillAnimation = gsap
        .timeline({ paused: true })
        .to(waterFillElement, {
          duration: 0.5,
          scaleX: 1,
          ease: "power2.out",
          x: -48,
        });
      setTimeline(waterFillAnimation);
    }

    // Only activate shine effect when glow prop is true
    if (glow) {
      gsap.to(ctaElement, {
        backgroundPosition: "300px 0px",
        duration: 1,
        repeat: -1,
        yoyo: true,
        repeatDelay: 2,
      });
    }
  }, [glow]);

  const handleMouseOver = () => {
    if (tl && !submitted && !disable) {
      tl.timeScale(1).play();
    }
  };

  const handleMouseLeave = () => {
    if (tl) {
      tl.timeScale(2).reverse();
    }
  };

  const buttonCTA = button ? (
    <button
      type="submit"
      ref={ctaRef}
      className={`${ctaClasses} ${glow ? "glow" : ""} ${className}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      disabled={disable}
    >
      <p>{children}</p>
      <div ref={waterFillRef} className="cs-cta__water-fill"></div>
    </button>
  ) : (
    <div
      ref={ctaRef}
      className={`${ctaClasses} ${glow ? "glow" : ""} ${className}`}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Link isAnchor={isAnchor} isOutbound={isOutbound} to={to}>
        {children}
      </Link>
      <div ref={waterFillRef} className="cs-cta__water-fill"></div>
    </div>
  );

  return <>{buttonCTA}</>;
};

export default CTA;
