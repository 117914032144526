import React from "react";
import { Link } from "../../components/links";
import CTA from "../../components/cta";
import "../../styles/pages/survey-results-index.scss";

const SurveyResultsIndex = () => {
  return (
    <div className="survey-results-index">
      <div className="survey-results-index__inner">
        <h1>Survey Results</h1>
        <div className="survey-results-index__ctas">
          {/* <div>
            <CTA to="/survey-results/2023">2023 Survey Results</CTA>
          </div> */}
          <div>
            <CTA to="/survey-results/2024">2024 Survey Results</CTA>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyResultsIndex;
